const data = {
    status: "succes",
    items: [
        {
            id:1,
            title:"San Benito",
            price:18,
            image: require("./imagenes/Collares/20230913_123112.jpg"),
            category:"Collares",
            img1:"https://m.stihl.com.ar/Productos-STIHL/Sopladoras-y-Sopla-aspiradoras/Sopladoras-a-gasolina/219968-85990/SH-86-C-ED.aspx",
            img2:"",
            cantidad:1,

        },
        {
            id:2,
            title:"Cruz estilo rosario",
            price:17,
            image: require("./imagenes/Collares/20230913_123313.jpg"),
            category:"Collares",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:3,
            title:"Perlas acrílicas, cristales y piedra Jaspe brechado rojo.",
            price:16,
            image: require("./imagenes/Collares/20230913_173036.jpg"),
            category:"Collares",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:4,
            title:"Elastizados individuales en madera",
            price:15,
            image: require("./imagenes/Collares/20230913_174123.jpg"),
            category:"Collares",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:5,
            title:"Luna de arcilla polimérica",
            price:14,
            image: require("./imagenes/Collares/20230913_173626.jpg"),
            category:"Collares",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:6,
            title:"Playeros en blanco y negro",
            price:13,
            image: require("./imagenes/Collares/20230913_175125.jpg"),
            category:"Collares",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:7,
            title:"Cancion animal.Piedra:Jaspe ",
            price:12,
            image: require("./imagenes/Piedras/20230913_115440.jpg"),
            category:"Piedras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:8,
            title:"Pulsera elastizada Ágata verde",
            price:11,
            image: require("./imagenes/Piedras/20230913_120124.jpg"),
            category:"Piedras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:9,
            title:"Universo triple cristal",
            price:10,
            image: require("./imagenes/Pulseras/20230913_114245.jpg"),
            category:"Piedras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:10,
            title:"En el borde Snake MIX",
            price:9,
            image: require("./imagenes/Piedras/20230913_122715.jpg"),
            category:"Piedras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:11,
            title:"Elastizada cristal CORNALINA",
            price:8,
            image: require("./imagenes/Piedras/20230913_121322.jpg"),
            category:"Piedras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:12,
            title:"Elastizada Ágata camel",
            price:7,
            image: require("./imagenes/Piedras/20230913_120815.jpg"),
            category:"Piedras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:13,
            title:"Elastizada cristales verde petróleo",
            price:6,
            image: require("./imagenes/Pulseras/20230913_112800.jpg"),
            category:"Pulseras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:14,
            title:"Universo triple cristal",
            price:5,
            image: require("./imagenes/Pulseras/20230913_114245.jpg"),
            category:"Pulseras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:15,
            title:"Cristal naranja y ágata.",
            price: 4,
            image: require("./imagenes/Pulseras/20230913_120620.jpg"),
            category:"Pulseras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:16,
            title:"Perlas de vidrio rojas",
            price: 3,
            image: require("./imagenes/Pulseras/20230913_114611.jpg"),
            category:"Pulseras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:17,
            title:"Vaquita cristal rojo",
            price: 2,
            image: require("./imagenes/Pulseras/20230913_114424.jpg"),
            category:"Pulseras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        {
            id:18,
            title:"Cristales y corazón negro",
            price: 1,
            image: require("./imagenes/Pulseras/20230913_113720.jpg"),
            category:"Pulseras",
            img1:"",
            img2:"",
            cantidad:1,

        },
        
    ],
};
export default data;


